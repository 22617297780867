import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layouts/';
import Heading from 'react-bulma-components/lib/components/heading';
import Columns from 'react-bulma-components/lib/components/columns';
import Img from 'gatsby-image';
import { Input } from 'react-bulma-components/lib/components/form';
import Button from 'react-bulma-components/lib/components/button';
import RelatedProducst from '../containers/RelatedProducts';

class ProductTemplate extends Component {
    state = {
        value: 1,
    };
    handleChange = e => {
        this.setState({ value: e.target.value });
    };
    render() {
        const { product, related_products } = this.props.data;
        return (
            <Layout page_layout="full-width-content">
                <Columns>
                    <Columns.Column>
                        {product.images.map((image, i) => (
                            <div key={i}>
                                <Img
                                    fluid={
                                        image.src.localFile.childImageSharp
                                            .fluid
                                    }
                                />
                            </div>
                        ))}
                    </Columns.Column>
                    <Columns.Column>
                        <Heading size={3}>{product.name}</Heading>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: product.description,
                            }}
                        />
                        {product.backorders_allowed &&
                            !product.stock_quantity && (
                                <p>Available on backorder</p>
                            )}
                        <Input
                            type="number"
                            placeholder="Text input"
                            value={this.state.value}
                            onChange={this.handleChange}
                        />
                        <Button color="primary">Add to Cart</Button>
                        <Heading size={6}>Product Dimensions</Heading>
                        <table className="table is-bordered is-striped is-hoverable is-fullwidth">
                            <tbody>
                                <tr>
                                    <td className="has-text-info">Weight</td>
                                    <td>{product.weight} lbs</td>
                                </tr>
                                <tr>
                                    <td className="has-text-info">
                                        Circumference
                                    </td>
                                    <td>{product.dimensions.width} in</td>
                                </tr>
                                <tr>
                                    <td className="has-text-info">Height</td>
                                    <td>{product.dimensions.height} in</td>
                                </tr>
                            </tbody>
                        </table>
                    </Columns.Column>
                </Columns>
                <RelatedProducst products={related_products} />
            </Layout>
        );
    }
}

export default ProductTemplate;

export const pageQuery = graphql`
    query($id: String!, $related_ids: [Int]) {
        product: wordpressWcProducts(id: { eq: $id }) {
            ...product
        }

        related_products: allWordpressWcProducts(
            filter: { wordpress_id: { in: $related_ids } }
            limit: 4
        ) {
            ...products
        }
    }
`;
