import React from 'react';
import styled from 'styled-components';
import Card from 'react-bulma-components/lib/components/card';
import Heading from 'react-bulma-components/lib/components/heading';
import Img from 'gatsby-image';

const CustomCard = styled(Card)`
    height: ${props => props.height || 'auto'};
`;

const ProductCard = ({ product, cardHeight }) => (
    <CustomCard key={product.wordpress_id} height={cardHeight}>
        <a href={`/product/${product.slug}`}>
            <Img
                fluid={product.images[0].src.localFile.childImageSharp.fluid}
            />
        </a>
        <Card.Content>
            <Heading size={6}>{product.name}</Heading>
        </Card.Content>
    </CustomCard>
);

export default ProductCard;
