import React from 'react';
import styled from 'styled-components';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import ProductCard from '../../components/ProductCard';

const ProductColumn = styled(Columns.Column).attrs({
    desktop: { size: 3 },
    tablet: { size: 4 },
    mobile: { size: 12 },
})``;

const RelatedProducts = ({ products }) => (
    <Section>
        <Columns>
            {products.edges.map(edge => {
                let product = edge.node;
                return (
                    <ProductColumn key={product.id}>
                        <ProductCard product={product} cardHeight="100%" />
                    </ProductColumn>
                );
            })}
        </Columns>
    </Section>
);

export default RelatedProducts;
